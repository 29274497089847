import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { linkResolver } from "../../utils/LinkResolver";
import { graphql } from "gatsby";
import { shuffle } from "../../utils/shuffleArray.js";
import { Layout } from "../../components/Layout";
import { RichText } from "prismic-reactjs";
import Seo from "../../components/Seo.js";
import { ArrowBack, ArrowNext } from "../../components/Icons/MadLogos.js";
import useResizeObserver from "use-resize-observer";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "./Selector.scss";

SwiperCore.use([Navigation]);

const SelectorTemplate = ({ location, data }) => {
  const selectedSelector = [data.prismicSelectors];
  const initArray = selectedSelector.concat(
    shuffle(data.allPrismicSelectors.nodes)
  );
  const defaultTitle = data.site.siteMetadata?.title;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeSelector] = useState(selectedSelector[0]);
  const [selectorArray] = useState(initArray);
  const [currentSection, setCurrentSection] = useState(false);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const sliderRef = useRef();
  const sliderControls = useRef();
  const sectionImage = useRef();
  const navigationRef = useRef();
  const url = location.pathname;

  const currentSectionRef = useCallback(
    (currentSectionNode) => setCurrentSection(currentSectionNode),
    []
  );

  const cumulativeOffset = function (element) {
    var top = 0,
      left = 0;
    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while (element);

    return {
      top: top,
      left: left,
    };
  };

  // useResizeObserver({
  //   ref: currentSection,
  //   onResize: function () {
  //     if (
  //       sectionImage?.current &&
  //       sliderControls?.current &&
  //       navigationPrevRef?.current &&
  //       navigationRef?.current &&
  //       sectionImage?.current
  //     ) {
  //       const { top } = cumulativeOffset(sectionImage.current);
  //       sliderControls.current.style.top = `${
  //         top -
  //         navigationPrevRef.current.offsetHeight / 2 -
  //         navigationRef.current.offsetHeight +
  //         sectionImage.current.offsetHeight / 2
  //       }px`;
  //     }
  //   },
  // });

  // useLayoutEffect(() => {
  //   if (
  //     sectionImage?.current &&
  //     sliderControls?.current &&
  //     navigationPrevRef?.current &&
  //     navigationRef?.current &&
  //     sectionImage?.current
  //   ) {
  //     const { top } = cumulativeOffset(sectionImage.current);
  //     sliderControls.current.style.top = `${
  //       top -
  //       navigationPrevRef.current.offsetHeight / 2 -
  //       navigationRef.current.offsetHeight +
  //       sectionImage.current.offsetHeight / 2
  //     }px`;
  //   }
  // }, [
  //   cumulativeOffset,
  //   sectionImage,
  //   sliderControls,
  //   navigationPrevRef,
  //   navigationRef,
  // ]);

  const changeUrl = (curSelected) => {
    window.history.pushState(
      "",
      curSelected.uid,
      "/selectors/".concat(curSelected.uid)
    );
  };

  useEffect(() => {
    changeUrl(selectorArray[currentIndex]);
    document.title = selectorArray[currentIndex].data.name.concat(
      defaultTitle ? ` | ${defaultTitle}` : ""
    );
  }, [currentIndex, selectorArray, defaultTitle]);

  const socialCards = data.prismicSelectors?.data?.body6;

  return (
    <Layout
      backTo={"/"}
      key="selector-page"
      backTo="/#selectors"
      ref={navigationRef}
    >
      <Seo
        title={activeSelector.data?.meta_title || activeSelector.data.name}
        description={activeSelector.data?.meta_description || ""}
        pageUrl={url}
        socialCards={socialCards}
      />
      <div className="selector-page selector-page__slide-container">
        <div
          className="selector-page__slide-container--controls"
          ref={sliderControls}
        >
          <div
            ref={navigationPrevRef}
            className={`selector-page__slide-container--prev`}
          >
            <div role="link" tabIndex={0}>
              <ArrowBack />
            </div>
          </div>
          <div
            ref={navigationNextRef}
            className={`selector-page__slide-container--next`}
          >
            <div role="link" tabIndex={0}>
              <ArrowNext />
            </div>
          </div>
        </div>
        <div className="selector-page__wrapper">
          <Swiper
            slidesPerView={1}
            autoHeight={true}
            height="auto"
            loop={true}
            autoplay={{
              delay: 13500,
            }}
            navigation={{
              prevEl: navigationPrevRef.current
                ? navigationPrevRef.current
                : undefined,
              nextEl: navigationNextRef.current
                ? navigationNextRef.current
                : undefined,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
              swiper.navigation.update();
            }}
            onSlideChange={(swiper) => {
              setCurrentIndex(swiper.realIndex);
            //   const { top } = cumulativeOffset(sectionImage.current);
            //   sliderControls.current.style.top = `${
            //     top -
            //     navigationPrevRef.current.offsetHeight / 2 -
            //     navigationRef.current.offsetHeight +
            //     sectionImage.current.offsetHeight / 2
            //   }px`;
            }}
            onSwiper={(swiper) => {
              sliderRef.current = swiper;
            }}
          >
            {selectorArray.map((selector, idx) => {
              return (
                <SwiperSlide>
                  <section
                    className={`selector-page__section slide ${
                      currentIndex === idx && "active"
                    }`}
                    key={selector.uid}
                    ref={(element) =>
                      currentIndex === idx ? currentSectionRef(element) : null
                    }
                  >
                    <div
                      className="selector-page__section--image"
                      ref={currentIndex === idx ? sectionImage : null}
                    >
                      <img
                        src={
                          selector.data.image.url
                            ? selector.data.image.url
                            : "https://via.placeholder.com/1920x1080/eee?text=16:9"
                        }
                        alt=""
                      />
                    </div>
                    <div className="selector-page__section--content">
                      <p className="selector-page__section--count">
                        {idx + 1}/{selectorArray.length}
                      </p>
                      <h1 className="selector-page__section--title typo__h2">
                        {selector.data.name}
                      </h1>
                      <p className="selector-page__section--position typo__h5">
                        {selector.data.position}
                      </p>
                      {/* <p className="typo__text">
                        {RichText.asText(selector.data.description.raw || "")}
                      </p> */}
                      <Swiper
                        slidesPerView={1}
                        autoHeight={true}
                        height="auto"
                        allowTouchMove={false}
                        pagination={{ clickable: true, type: 'bullets' }}
                        autoplay={{
                          delay: 2500,
                        }}
                        onSlideChange={(swiper) => {
                          setTimeout(function(){
                            sliderRef.current.updateAutoHeight(100);
                          }, 300);
                        }}
                      >
                        <ul className="selector-page__section--question-list">
                          {selector.data.questions.map((question, innerIdx) => {
                            return (
                              <SwiperSlide>
                                <li key={`listItem-${innerIdx}`}>
                                  <div
                                    key={`question-${innerIdx}`}
                                    className="selector-page__section--question"
                                  >
                                    <span>
                                      {question.question.split(/(,)/)[0]}{question.question.split(/(,)/)[1]}<br/>
                                      {question.question.split(/(,)/)[2]}</span>
                                  </div>
                                  <div
                                    key={`answer-${innerIdx}`}
                                    className="selector-page__section--answer"
                                  >
                                    <span>{question.answer}</span>
                                  </div>
                                </li>
                              </SwiperSlide>
                            );
                          })}
                        </ul>
                      </Swiper>
                    </div>
                  </section>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query SelectorQuery($id: String) {
    prismicSelectors(id: { eq: $id }) {
      data {
        name
        position
        description {
          raw
        }
        image {
          url
        }
        questions {
          answer
          question
        }
        meta_description
        meta_title
        body6 {
          ... on PrismicSelectorsDataBody6GeneralCard {
            slice_type
            primary {
              title {
                text
              }
              description {
                text
              }
              image {
                url
              }
            }
          }
          ... on PrismicSelectorsDataBody6TwitterCard {
            slice_type
            primary {
              title {
                text
              }
              description {
                text
              }
              image {
                url
              }
            }
          }
        }
      }
      uid
    }
    allPrismicSelectors(filter: { id: { ne: $id } }) {
      nodes {
        uid
        data {
          name
          position
          questions {
            answer
            question
          }
          description {
            raw
          }
          image {
            url
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
        url
      }
    }
  }
`;

export default withPrismicPreview(SelectorTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
